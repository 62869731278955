import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";
import SimpleHeader from "../components/SimpleHeader";

// eslint-disable-next-line
export const KamsaPageTemplate = ({ seoTitle, seoDescription, title, videoLink }) => {
  const videoLinkWithParams = videoLink + '&rel=0&controls=0&modestbranding=1'
  return (
    <>
      <section>
        <div className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{seoTitle}</title>
            <meta property="og:title" content={seoTitle} />
            <meta name="description" content={seoDescription} />
          </Helmet>
        </div>
      </section>
      <section className="section section--gradient is-small">
        <div className="container has-text-centered">
          <div className="columns is-mobile is-multiline">
            <SimpleHeader header={title} columnSize={"is-12"} isPageHeader />
            <div className="column is-12">
              <iframe
                style={{ maxWidth: "100%" }}
                width="850"
                height="480"
                src={videoLinkWithParams}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

KamsaPageTemplate.propTypes = {
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
  title: PropTypes.string,
  videoLink: PropTypes.string
};

const KamsaPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <KamsaPageTemplate
        seoTitle={frontmatter.seoTitle}
        seoDescription={frontmatter.seoDescription}
        title={frontmatter.title}
        videoLink={frontmatter.videoLink}
      />
    </Layout>
  );
};

KamsaPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default KamsaPage;

export const kamsaPageQuery = graphql`
  query KamsaPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        seoDescription
        seoTitle
        videoLink
      }
    }
  }
`;
